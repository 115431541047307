/*eslint-disable*/
import { memo, useEffect } from 'react'
import { HomeWrapper } from './styled'
import { useWeb3React } from '@web3-react/core'
import useDataHooks from '@/hooks/useDataHooks'
import type { ConstantInitTypes } from '@/contracts/constant.init'
import { useSelector } from 'react-redux'
import { selectAddress } from '@/store/user'
import { useTranslation } from 'react-i18next'

export default memo(function MarketPage() {
  const { isActive, provider } = useWeb3React()
  const { t } = useTranslation()

  const myAddress = useSelector(selectAddress)
  const dataInit: ConstantInitTypes = useDataHooks()
  const { constant, Swap_ADDRESS } = dataInit
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return <HomeWrapper>market</HomeWrapper>
})
