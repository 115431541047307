/*eslint-disable*/
import { memo, useEffect } from 'react'
import { HomeWrapper } from './styled'
import { useTranslation } from 'react-i18next'
import { Collapse } from 'antd'

const { Panel } = Collapse

export default memo(function QuestionPage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { t } = useTranslation()

  const text = (
    <p>
      A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households
      across the world.
    </p>
  )

  return (
    <HomeWrapper>
      <div className="topic">{t('question.topic')}</div>
      <div className="questionList">
        <Collapse bordered={false} defaultActiveKey={['1']} expandIconPosition="right">
          <Panel header="This is panel header 1" key="1">
            {text}
          </Panel>
          <Panel header="This is panel header 2" key="2">
            {text}
          </Panel>
          <Panel header="This is panel header 3" key="3">
            {text}
          </Panel>
        </Collapse>
      </div>
    </HomeWrapper>
  )
})
