import styled, { css } from 'styled-components'
import { h5LayoutAdaptation, flatLayoutAdaptation, webLayoutAdaptation, webLayoutAdaptationMax } from '@/common/styled'

export const HomeWrapper = styled.div`
  ${h5LayoutAdaptation}
  width: 100%;
  min-height: calc(100vh - 7.5rem - 5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.theme.mediaWidth.md(
      () => css`
        ${flatLayoutAdaptation}
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.mxl(
      () => css`
        ${webLayoutAdaptation}
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.maxl(
      () => css`
        ${webLayoutAdaptationMax}
      `,
    )}
`

export const HomeContent = styled.div`
  width: 100%;
  min-height: 27.5rem;
  background: rgb(255, 255, 255);
  box-shadow: rgb(146 159 198 / 10%) 0rem 1.94rem 1.88rem 0rem;
  border-radius: 1.88rem;
  position: relative;
  cursor: pointer;
  .ant-spin-container {
    min-height: 27.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`
