import styled, { css } from 'styled-components'
import { h5LayoutAdaptation, flatLayoutAdaptation, webLayoutAdaptation, webLayoutAdaptationMax } from '@/common/styled'

export const HomeWrapper = styled.div`
  ${h5LayoutAdaptation}
  width: 100%;
  min-height: calc(100vh - 6.25rem - 5rem);
  ${(props) =>
    props.theme.mediaWidth.md(
      () => css`
        ${flatLayoutAdaptation}
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.mxl(
      () => css`
        ${webLayoutAdaptation}
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.maxl(
      () => css`
        /* ${webLayoutAdaptationMax} */
        max-width: 100%;
      `,
    )}

    .topic {
    padding: 4.125rem 0 4.75rem 0;
    font-size: 2.25rem;
    font-weight: 600;
    color: #000000;
    text-align: center;
  }
  .questionList {
    width: 56.1875rem;
    margin: 0 auto;
    .ant-collapse {
      background-color: #fff !important;
      .ant-collapse-header {
        font-size: 1.25rem !important;
        font-weight: 600 !important;
        color: #000000 !important;
      }
      .ant-collapse-content {
        font-size: 1.25rem;
        font-weight: 400;
        color: #666666;
      }
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`
