/*eslint-disable*/
import { memo, useEffect } from 'react'
import { HomeWrapper } from './styled'
import { useTranslation } from 'react-i18next'

export default memo(function PledgePage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { t } = useTranslation()

  return (
    <HomeWrapper>
      <div className="content">{t('pledge.tips')}</div>
    </HomeWrapper>
  )
})
