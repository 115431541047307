import styled, { css } from 'styled-components'
import { h5LayoutAdaptation, flatLayoutAdaptation, webLayoutAdaptation, webLayoutAdaptationMax } from '@/common/styled'

export const HomeWrapper = styled.div`
  ${h5LayoutAdaptation}
  width: 100%;
  min-height: calc(100vh - 6.25rem - 5rem);
  .spans {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2.5rem;
    margin-bottom: 3.13rem;
  }
  .spansss {
    margin-top: 1.25rem;
    width: 95%;
    word-break: break-all;
    text-align: center;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.theme.mediaWidth.md(
      () => css`
        ${flatLayoutAdaptation}
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.mxl(
      () => css`
        ${webLayoutAdaptation}
      `,
    )}
  ${(props) =>
    props.theme.mediaWidth.maxl(
      () => css`
        ${webLayoutAdaptationMax}
      `,
    )}

    .content {
    font-size: 1.5rem;
  }
`
